/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-turn-left-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM7 8.466a.25.25 0 01-.41.192L4.23 6.692a.25.25 0 010-.384l2.36-1.966a.25.25 0 01.41.192V6h1.5A2.5 2.5 0 0111 8.5V11h-1V8.5A1.5 1.5 0 008.5 7H7z"/>',
    },
});
